var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DefaultCrudView',{attrs:{"actions":_vm.crudActions,"breadcrumbsItems":_vm.breadcrumbs,"containerLarge":""},on:{"create-new":_vm.newSecurityMark}},[_c('template',{slot:"content"},[(this.selectedSecurityGroup.match_type == 'tree')?_c('v-card',[_c('v-treeview',{attrs:{"items":_vm.tree,"open":_vm.allMarksId},on:{"update:open":function($event){_vm.allMarksId=$event}},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('v-list-item-icon',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.editSecurityMark(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.modalDeleteSecurityMark(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.addChildMark(item)}}},[_c('v-icon',[_vm._v("mdi-bookmark-plus")])],1)],1)],1)]}}],null,false,3448707681)})],1):_vm._e(),(_vm.selectedSecurityGroup.match_type && _vm.selectedSecurityGroup.match_type != 'tree')?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.marks,"loading":_vm.loading,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.mark_category_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCategoryKeyById(item.mark_category_id))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){return _vm.editSecurityMark(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('edit')))])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){return _vm.modalDeleteSecurityMark(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('delete')))])])]}}],null,false,1461559300)}):_vm._e(),_c('DefaultModal',{ref:"modal",attrs:{"title":_vm.getModalTitle()},on:{"submit":_vm.save},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.selectedSecurityGroup.match_type == 'tree')?_c('v-breadcrumbs',{attrs:{"items":_vm.treviewBreadcrumbs,"color":"primary","large":""},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}],null,false,3681283568)}):_vm._e(),_c('DynamicFormContent',{attrs:{"fields":_vm.fields}})]},proxy:true}])}),_c('DefaultModal',{ref:"deleteModal",attrs:{"title":_vm.$t('delete_security_mark'),"submit-button-text":"delete","deleteModal":""},on:{"submit":_vm.removeSecurityMark},scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('remove_security_mark_confirmation'))+" "),_c('b',[_vm._v(_vm._s(_vm.selectedSecurityMark ? _vm.selectedSecurityMark.security_mark_key || _vm.selectedSecurityMark.name : ''))]),_vm._v("? ")]},proxy:true}])})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }