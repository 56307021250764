import { render, staticRenderFns } from "./SecurityMark.vue?vue&type=template&id=116605e6&scoped=true&"
import script from "./SecurityMark.vue?vue&type=script&lang=js&"
export * from "./SecurityMark.vue?vue&type=script&lang=js&"
import style0 from "./SecurityMark.vue?vue&type=style&index=0&id=116605e6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "116605e6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VBreadcrumbs,VBtn,VCard,VDataTable,VIcon,VListItem,VListItemIcon,VListItemTitle,VTooltip,VTreeview})
