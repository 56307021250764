<template>
  <DefaultCrudView
    @create-new="newSecurityMark"
    :actions="crudActions"
    :breadcrumbsItems="breadcrumbs"
    containerLarge
  >
    <template slot="content">
      <v-card
        v-if="this.selectedSecurityGroup.match_type == 'tree'"
      >
        <v-treeview
          :items="tree"
          :open.sync="allMarksId"
        >
          <template v-slot:label="{ item }">
            <v-list-item>
              <v-list-item-title>
                {{ item.name }}
              </v-list-item-title>

              <v-list-item-icon>
                <v-btn @click="editSecurityMark(item)" icon>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>

                <v-btn @click="modalDeleteSecurityMark(item)" icon>
                  <v-icon>mdi-delete</v-icon>
                </v-btn>

                <v-btn @click="addChildMark(item)" icon>
                  <v-icon>mdi-bookmark-plus</v-icon>
                </v-btn>
              </v-list-item-icon>
            </v-list-item>
          </template>
        </v-treeview>
      </v-card>

      <v-data-table
        v-if="selectedSecurityGroup.match_type && selectedSecurityGroup.match_type != 'tree'"
        :headers="headers"
        :items="marks"
        class="elevation-1"
        :loading="loading"
        hide-default-footer
      >
        <template v-slot:item.mark_category_id="{ item }">
          {{ getCategoryKeyById(item.mark_category_id) }}
        </template>

        <template v-slot:item.actions="{ item }">

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click="editSecurityMark(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>{{ $t('edit') }}</span>
          </v-tooltip>

          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click="modalDeleteSecurityMark(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>{{ $t('delete') }}</span>
          </v-tooltip>

        </template>

      </v-data-table>

      <DefaultModal :title="getModalTitle()" ref="modal" v-on:submit="save">
        <template v-slot:content>
          <v-breadcrumbs
            :items="treviewBreadcrumbs"
            v-if="selectedSecurityGroup.match_type == 'tree'"
            color="primary"
            large
          >
            <template v-slot:divider>
              <v-icon>mdi-chevron-right</v-icon>
            </template>
          </v-breadcrumbs>
          <DynamicFormContent :fields="fields"></DynamicFormContent>
        </template>
      </DefaultModal>

      <DefaultModal
        :title="$t('delete_security_mark')"
        ref="deleteModal"
        @submit="removeSecurityMark"
        submit-button-text="delete"
        deleteModal
      >
        <template v-slot:content>
          {{ $t('remove_security_mark_confirmation') }} <b>{{ selectedSecurityMark ? selectedSecurityMark.security_mark_key || selectedSecurityMark.name : '' }}</b>?
        </template>
      </DefaultModal>

    </template>
  </DefaultCrudView>

</template>

<style scoped>
  .container {
    padding: 0;
  }
</style>

<script>

import { mapActions, mapMutations, mapState } from 'vuex'

import DefaultCrudView from '@/components/DefaultCrudView.vue'
import DefaultModal from '@/components/DefaultModal.vue'
import DynamicFormContent from '@/components/DynamicFormContent.vue'

import SecurityGroupActions from '@/store/operation/security_group/actions-types'
import SecurityMarkActions from '@/store/operation/security_mark/actions-types'
import SecurityCategoryActions from '@/store/operation/security_category/actions-types'
import SecurityMarkMutations from '@/store/operation/security_mark/mutations-types'
import AppActions from '@/store/app/actions-types'

export default {
  name: "SecurityMark",

  components: {
    DefaultCrudView,
    DefaultModal,
    DynamicFormContent,
  },

  data() {
    return {
      crudActions: [
        {
          text: "create_security_mark",
          eventToEmit: "create-new",
        }
      ],
      marks: [],
      tree: [],
      loading: false,
      categories: [],
      allMarksId: [],
    }
  },

  mounted() {
    this[SecurityCategoryActions.GET_ALL_SECURITYCATEGORIES]({limit: 1000, order: 'mark_category_key', direction: 'asc'})
      .then((result) => {
        this.categories = result.items
        this[SecurityMarkMutations.SET_FIELD_SECURITY_CATEGORY](result.items)

        if (!this.selectedSecurityGroup.security_group_id) {
          this.loading = true
          this[SecurityGroupActions.GET_SECURITYGROUP](this.$route.params.securityGroupId)
            .then(() => {
              this.loadMarks()
            })
        } else {
          this.loadMarks()
        }
      })
  },

  computed: {
    ...mapState({
      fields: state => state.operation.security_mark.fields,
      headers: state => state.operation.security_mark.tableHeaders,
      selectedSecurityGroup: state => state.operation.security_group.selectedSecurityGroup,
      selectedSecurityMark: state => state.operation.security_mark.selectedSecurityMark,
      selectedParentMark: state => state.operation.security_mark.selectedParentMark,
    }),

    breadcrumbs() {
      return [
        {
          text: this.$t('security_groups'),
          prependIcon: 'mdi-security',
          iconColor: 'primary',
          to: {
            name: "SecurityGroup"
          },
          disabled: false,
          exact: true,
          ripple: true,
        },
        {
          text: this.selectedSecurityGroup ? this.selectedSecurityGroup.security_group_key : '...',
        },
        {
          text: this.$t('Marks')
        }
      ]
    },

    treviewBreadcrumbs() {
      if (!this.selectedParentMark) {
        return []
      }

      const marks = this.getParentMarks(this.selectedParentMark.id).reverse()

      let items = []

      marks.forEach(mark => items.push({
        text: mark.security_mark_key,
        disabled: mark.security_mark_id != this.selectedParentMark.id
      }))

      return items
    }
  },

  methods: {
    ...mapActions('operation/security_category', [
      SecurityCategoryActions.GET_ALL_SECURITYCATEGORIES
    ]),

    ...mapActions('operation/security_group', [
      SecurityGroupActions.GET_SECURITYGROUP,
      SecurityGroupActions.GET_SECURITY_MARKS_BY_SECURITY_GROUP
    ]),

    ...mapActions('operation/security_mark', [
      SecurityMarkActions.SAVE_SECURITY_MARK,
      SecurityMarkActions.DELETE_SECURITY_MARK,
    ]),

    ...mapActions('app', [
      AppActions.OPEN_APP_SUCCESS_MESSAGE
    ]),

    ...mapMutations('operation/security_mark', [
      SecurityMarkMutations.SET_SELECTED_SECURITY_MARK,
      SecurityMarkMutations.SET_SELECTED_PARENT_MARK,
      SecurityMarkMutations.SET_FIELD_SECURITY_CATEGORY,
    ]),

    getParentMarks(markId) {
      let mark = this.marks.find(mark => mark.security_mark_id == markId)

      if (!mark) {
        return []
      }

      let marks = []
      marks.push(mark)

      if (mark.mark_parent) {
        marks = marks.concat(this.getParentMarks(mark.mark_parent))
      }

      return marks
    },

    loadMarks() {
      if (!this.selectedSecurityGroup.security_group_id) {
        return
      }

      this.loading = true

      this[SecurityGroupActions.GET_SECURITY_MARKS_BY_SECURITY_GROUP](this.selectedSecurityGroup.security_group_id)
        .then((result) => {
          this.marks = result.items

          if (this.selectedSecurityGroup.match_type == 'tree') {
            this.fetchMarkTree()
          }

          this.allMarksId = result.items.map(mark => mark.security_mark_id)

          this.loading = false
        })
        .catch(() => this.loading = false)
    },

    fetchMarkTree() {
      let items = []
      let marks = this.marks

      marks.forEach((mark) => {
        if (mark.mark_parent == null) {
          items.push({
            id: mark.security_mark_id,
            name: mark.security_mark_key + ' / ' + this.getCategoryKeyById(mark.mark_category_id),
            children: this.fetchTreeviewChildrenItems(mark.security_mark_id, marks)
          })
        }
      })

      this.tree = items
    },


    fetchTreeviewChildrenItems(securityMarkId, marks) {
      let items = []

      marks.forEach(mark => {
        if (mark.mark_parent == securityMarkId) {
          items.push({
            id: mark.security_mark_id,
            name: mark.security_mark_key  + ' / ' + this.getCategoryKeyById(mark.mark_category_id),
            children: this.fetchTreeviewChildrenItems(mark.security_mark_id, marks)
          })
        }
      })

      return items
    },

    getModalTitle() {
      if (this.selectedSecurityGroup.match_type == 'tree') {
        return this.selectedSecurityMark ? this.$t('Edit security mark') : this.$t('Create new child mark')
      }

      return this.selectedSecurityMark ? this.$t('Edit security mark') : this.$t('New security mark')
    },

    save() {
      this.$refs.modal.errorMessage = null
      this.$refs.modal.errorList = []

      this[SecurityMarkActions.SAVE_SECURITY_MARK](this.selectedSecurityGroup)
        .then(() => {
          let message = this.selectedSecurityMark ? 'security_mark_edited' : 'security_mark_created'
          this[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t(message))

          this[SecurityMarkMutations.SET_SELECTED_PARENT_MARK](null)

          this.$refs.modal.closeModal()
          this.loadMarks()
        })
        .catch(() => this.$refs.modal.submitting = false)
    },

    newSecurityMark() {
      this[SecurityMarkMutations.SET_SELECTED_SECURITY_MARK](null)
      this.$refs.modal.openModal()
    },

    getCategoryKeyById(categoryId) {
      let category = this.categories.find(item => item.mark_category_id == categoryId)

      return category ? category.mark_category_key : ''
    },

    editSecurityMark(item) {
      if (this.selectedSecurityGroup.match_type == 'tree') {
        let selectedMark = this.marks.find(mark => mark.security_mark_id == item.id)
        this[SecurityMarkMutations.SET_SELECTED_SECURITY_MARK](selectedMark)
      } else {
        this[SecurityMarkMutations.SET_SELECTED_SECURITY_MARK](item)
      }

      this.$refs.modal.openModal()
    },

    addChildMark(item) {
      this[SecurityMarkMutations.SET_SELECTED_PARENT_MARK](item)

      this[SecurityMarkMutations.SET_SELECTED_SECURITY_MARK](null)
      this.$refs.modal.openModal()
    },

    modalDeleteSecurityMark(mark) {
      this[SecurityMarkMutations.SET_SELECTED_SECURITY_MARK](mark)
      this.$refs.deleteModal.openModal()
    },

    removeSecurityMark() {
      this[SecurityMarkActions.DELETE_SECURITY_MARK]()
        .then(() => {
          this[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('security_mark_removed_successfully'))

          this.$refs.deleteModal.closeModal()
          this.loadMarks()
        })
        .catch(() => this.$refs.deleteModal.submitting = false)
    },
  }
}

</script>